<!--
    描述：个人资料页面
    时间：2021-11-09
    Created by 王帅
-->
<template>
    <div class="userInfo-layout">
        <el-tabs class="user-tabs" v-model="activeTab" @tab-click="onChangeTab">
            <el-tab-pane
                v-for="(item, index) in tabsList"
                :key="'tab' + index"
                :label="item.title"
                :name="item.attr"
            >
                <el-scrollbar
                    wrap-class="scrollbar-wrapper"
                    class="h100"
                    v-loading="btnLoading"
                >
                    <!-- 基本信息 -->
                    <template v-if="item.attr === 'baseInfo'">
                        <el-form
                            ref="baseForm"
                            :model="baseForm"
                            :rules="baseRules"
                            size="small"
                            :show-message="false"
                            label-width="120px"
                        >
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="姓名" prop="sname">
                                        <el-input
                                            :disabled="isDisabled"
                                            v-model="baseForm.sname"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="用户名" prop="username">
                                        <el-input
                                            disabled
                                            v-model="baseForm.username"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="手机号" prop="phone">
                                        <el-input
                                            disabled
                                            v-model="baseForm.phone"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="身份证号码" prop="idCard">
                                        <el-input
                                            :disabled="isDisabled"
                                            v-model="baseForm.idCard"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="性别" prop="sex">
                                        <el-radio-group disabled v-model="baseForm.sex">
                                            <el-radio :label="1">男</el-radio>
                                            <el-radio :label="0">女</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="民族" prop="nation">
                                        <el-select
                                            v-model="baseForm.nation"
                                            :disabled="isDisabled"
                                        >
                                            <el-option
                                                v-for="(m, n) in mz"
                                                :key="'民族' + n"
                                                :label="m.sname"
                                                :value="m.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="出生日期" prop="birthDate">
                                        <el-date-picker
                                            v-model="baseForm.birthDate"
                                            type="date"
                                            disabled
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="年龄" prop="age">
                                        <el-input
                                            disabled
                                            v-model="baseForm.age"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </template>

                    <!-- 工作经验 -->
                    <template v-if="item.attr === 'work'">
                        <div
                            class="pane-list-container"
                            v-for="(workForm, inx) in workList"
                            :key="'workList' + inx"
                        >
                            <div class="pane-list-item">
                                <el-form
                                    :ref="'workForm' + inx"
                                    :model="workForm"
                                    size="small"
                                    :show-message="false"
                                    label-width="120px"
                                >
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item label="时间">
                                                <el-date-picker
                                                    v-model="workForm.dateRange"
                                                    type="daterange"
                                                    :disabled="isDisabled"
                                                    range-separator="到"
                                                    format="yyyy-MM-dd"
                                                    value-format="yyyy-MM-dd"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                >
                                                </el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="公司" prop="company">
                                                <el-input
                                                    :disabled="isDisabled"
                                                    v-model="workForm.company"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="公司性质" prop="type">
                                                <el-select
                                                    v-model="workForm.type"
                                                    :disabled="isDisabled"
                                                >
                                                    <el-option
                                                        v-for="(m, n) in dwxz"
                                                        :key="'公司性质' + n"
                                                        :label="m.sname"
                                                        :value="m.value"
                                                    >
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="职能" prop="functions">
                                                <el-input
                                                    :disabled="isDisabled"
                                                    v-model="workForm.functions"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="行业" prop="industry">
                                                <el-input
                                                    :disabled="isDisabled"
                                                    v-model="workForm.industry"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="职位" prop="postion">
                                                <el-input
                                                    :disabled="isDisabled"
                                                    v-model="workForm.postion"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="岗位" prop="post">
                                                <el-input
                                                    :disabled="isDisabled"
                                                    v-model="workForm.post"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item
                                                label="工作描述"
                                                prop="description"
                                            >
                                                <el-input
                                                    type="textarea"
                                                    :disabled="isDisabled"
                                                    v-model="workForm.description"
                                                    :autosize="{ minRows: 2 }"
                                                    maxlength="1000"
                                                    show-word-limit
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="工作类型">
                                                <el-radio-group
                                                    v-model="workForm.jobType"
                                                    :disabled="isDisabled"
                                                >
                                                    <el-radio-button :label="0"
                                                        >兼职</el-radio-button
                                                    >
                                                    <el-radio-button :label="1"
                                                        >全职</el-radio-button
                                                    >
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </div>
                            <div class="pane-list-btn" v-show="!isDisabled">
                                <el-button
                                    size="small"
                                    type="primary"
                                    class="mb10"
                                    icon="el-icon-plus"
                                    @click="addItem(inx)"
                                ></el-button>
                                <el-button
                                    size="small"
                                    type="danger"
                                    icon="el-icon-minus"
                                    @click="removeItem(workForm, inx)"
                                    v-if="inx !== 0"
                                ></el-button>
                            </div>
                        </div>
                    </template>

                    <!-- 项目经验 -->
                    <template v-if="item.attr === 'project'">
                        <div
                            class="pane-list-container"
                            v-for="(projectForm, inx) in projectList"
                            :key="'projectList' + inx"
                        >
                            <div class="pane-list-item">
                                <el-form
                                    :ref="'projectForm' + inx"
                                    :model="projectForm"
                                    size="small"
                                    :show-message="false"
                                    label-width="120px"
                                >
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="所属公司" prop="company">
                                                <el-input
                                                    :disabled="isDisabled"
                                                    v-model="projectForm.company"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="时间">
                                                <el-date-picker
                                                    v-model="projectForm.dateRange"
                                                    type="daterange"
                                                    :disabled="isDisabled"
                                                    range-separator="到"
                                                    format="yyyy-MM-dd"
                                                    value-format="yyyy-MM-dd"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                >
                                                </el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="项目名称" prop="proName">
                                                <el-input
                                                    :disabled="isDisabled"
                                                    v-model="projectForm.proName"
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item label="项目描述" prop="proDes">
                                                <el-input
                                                    type="textarea"
                                                    :disabled="isDisabled"
                                                    v-model="projectForm.proDes"
                                                    :autosize="{ minRows: 2 }"
                                                    maxlength="1000"
                                                    show-word-limit
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item label="责任描述" prop="dutyDes">
                                                <el-input
                                                    type="textarea"
                                                    :disabled="isDisabled"
                                                    v-model="projectForm.dutyDes"
                                                    :autosize="{ minRows: 2 }"
                                                    maxlength="1000"
                                                    show-word-limit
                                                ></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </div>
                            <div class="pane-list-btn" v-show="!isDisabled">
                                <el-button
                                    size="small"
                                    type="primary"
                                    class="mb10"
                                    icon="el-icon-plus"
                                    @click="addItem(inx)"
                                ></el-button>
                                <el-button
                                    size="small"
                                    type="danger"
                                    icon="el-icon-minus"
                                    @click="removeItem(projectForm, inx)"
                                    v-if="inx !== 0"
                                ></el-button>
                            </div>
                        </div>
                    </template>

                    <!-- 学历 -->
                    <template v-if="item.attr === 'education'">
                        <el-row
                            class="pane-list-container"
                            v-for="(educationForm, inx) in educationList"
                            :key="'educationList' + inx"
                        >
                            <el-col :span="4" class="upload-container">
                                <div class="upload-box">
                                    <p>学历证书</p>
                                    <my-upload
                                        :ref="`education0_${inx}`"
                                        :isDisabled="isDisabled"
                                        width="200px"
                                        height="120px"
                                        listType="picture"
                                        :maxNum="1"
                                        :aid="educationForm.id"
                                        tableName="sys_user_edu"
                                        :assortment="`edu0_${educationForm.id}`"
                                        :acceptType="['PNG', 'JPG', 'JPEG', 'PDF']"
                                    ></my-upload>
                                </div>
                                <div class="upload-box">
                                    <p>学位证书</p>
                                    <my-upload
                                        :ref="`education1_${inx}`"
                                        :isDisabled="isDisabled"
                                        width="200px"
                                        height="120px"
                                        listType="picture"
                                        :maxNum="1"
                                        :aid="educationForm.id"
                                        tableName="sys_user_edu"
                                        :assortment="`edu1_${educationForm.id}`"
                                        :acceptType="['PNG', 'JPG', 'JPEG', 'PDF']"
                                    ></my-upload>
                                </div>
                            </el-col>
                            <el-col :span="20" class="flex">
                                <div class="pane-list-item">
                                    <el-form
                                        :ref="'educationForm' + inx"
                                        :model="educationForm"
                                        size="small"
                                        :show-message="false"
                                        label-width="120px"
                                    >
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="证书编号"
                                                    prop="cerNo"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="educationForm.cerNo"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="学历"
                                                    prop="education"
                                                >
                                                    <el-select
                                                        v-model="educationForm.education"
                                                        :disabled="isDisabled"
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in xlList"
                                                            :key="'学历' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="学历性质"
                                                    prop="degreeNature"
                                                >
                                                    <el-select
                                                        v-model="
                                                            educationForm.degreeNature
                                                        "
                                                        :disabled="isDisabled"
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in xlxz"
                                                            :key="'学历性质' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="证书取得日期"
                                                    prop="issueDate"
                                                >
                                                    <el-date-picker
                                                        v-model="educationForm.issueDate"
                                                        type="date"
                                                        :disabled="isDisabled"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="年-月-日"
                                                    >
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="毕业院校"
                                                    prop="academy"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="educationForm.academy"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="专业"
                                                    prop="profession"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="educationForm.profession"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="学位名称"
                                                    prop="degreeName"
                                                >
                                                    <el-select
                                                        v-model="educationForm.degreeName"
                                                        :disabled="isDisabled"
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in xwlx"
                                                            :key="'学位类型' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="学位类型"
                                                    prop="degreeType"
                                                >
                                                    <el-select
                                                        v-model="educationForm.degreeType"
                                                        :disabled="isDisabled"
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in xwzy"
                                                            :key="'学位类型' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="学制 (x 年)"
                                                    prop="years"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="educationForm.years"
                                                    >
                                                        <template slot="append"
                                                            >年</template
                                                        >
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="是否全日制"
                                                    prop="isFull"
                                                >
                                                    <vxe-switch
                                                        :disabled="isDisabled"
                                                        v-model="educationForm.isFull"
                                                        open-label="是"
                                                        :open-value="1"
                                                        close-label="否"
                                                        :close-value="0"
                                                    ></vxe-switch>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                </div>
                                <div class="pane-list-btn" v-show="editable">
                                    <el-button
                                        size="small"
                                        type="primary"
                                        class="mb10"
                                        icon="el-icon-plus"
                                        @click="addItem(inx)"
                                    ></el-button>
                                    <el-button
                                        size="small"
                                        type="danger"
                                        icon="el-icon-minus"
                                        @click="removeItem(educationForm, inx)"
                                        v-if="inx !== 0"
                                    ></el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </template>

                    <!-- 职称 -->
                    <template v-if="item.attr === 'career'">
                        <el-row
                            class="pane-list-container"
                            v-for="(careerForm, inx) in careerList"
                            :key="'careerList' + inx"
                        >
                            <el-col :span="4" class="upload-container">
                                <div class="upload-box">
                                    <p>职称证书</p>
                                    <my-upload
                                        :ref="`career0_${inx}`"
                                        :isDisabled="isDisabled"
                                        width="200px"
                                        height="120px"
                                        listType="picture"
                                        :maxNum="1"
                                        :aid="careerForm.id"
                                        tableName="sys_user_practice"
                                        :assortment="`career0_${careerForm.id}`"
                                        :acceptType="['PNG', 'JPG', 'JPEG', 'PDF']"
                                    ></my-upload>
                                </div>
                            </el-col>
                            <el-col :span="20" class="flex">
                                <div class="pane-list-item">
                                    <el-form
                                        :ref="'careerForm' + inx"
                                        :model="careerForm"
                                        size="small"
                                        :show-message="false"
                                        label-width="120px"
                                    >
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="职称证书编号"
                                                    prop="cerNo"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="careerForm.cerNo"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="职称名称"
                                                    prop="cerName"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="careerForm.cerName"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="职称"
                                                    prop="cerGrade"
                                                >
                                                    <el-select
                                                        v-model="
                                                            careerForm.cerGrade
                                                        "
                                                        :disabled="isDisabled"
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in grzc"
                                                            :key="'职称' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="职称专业"
                                                    prop="profession"
                                                >
                                                    <el-select
                                                        v-model="careerForm.profession"
                                                        :disabled="isDisabled"
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in zczy"
                                                            :key="'职称专业' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="评审时间"
                                                    prop="inspectDate"
                                                >
                                                    <el-date-picker
                                                        v-model="careerForm.inspectDate"
                                                        type="date"
                                                        :disabled="isDisabled"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="年-月-日"
                                                    >
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="评审单位"
                                                    prop="inspectUnit"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="careerForm.inspectUnit"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                </div>
                                <div class="pane-list-btn" v-show="editable">
                                    <el-button
                                        size="small"
                                        type="primary"
                                        class="mb10"
                                        icon="el-icon-plus"
                                        @click="addItem(inx)"
                                    ></el-button>
                                    <el-button
                                        size="small"
                                        type="danger"
                                        icon="el-icon-minus"
                                        @click="removeItem(careerForm, inx)"
                                        v-if="inx !== 0"
                                    ></el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </template>

                    <!-- 执业资格 -->
                    <template v-if="item.attr === 'qualification'">
                        <el-row
                            class="pane-list-container"
                            v-for="(qualiForm, inx) in qualiList"
                            :key="'qualiList' + inx"
                        >
                            <el-col :span="4" class="upload-container">
                                <div class="upload-box">
                                    <p>执业资格证书</p>
                                    <my-upload
                                        :ref="`qualification0_${inx}`"
                                        :isDisabled="isDisabled"
                                        width="200px"
                                        height="120px"
                                        listType="picture"
                                        :maxNum="1"
                                        :aid="qualiForm.id"
                                        tableName="sys_user_cer"
                                        :assortment="`qualification0_${qualiForm.id}`"
                                        :acceptType="['PNG', 'JPG', 'JPEG', 'PDF']"
                                    ></my-upload>
                                </div>
                            </el-col>
                            <el-col :span="20" class="flex">
                                <div class="pane-list-item">
                                    <el-form
                                        :ref="'qualiForm' + inx"
                                        :model="qualiForm"
                                        size="small"
                                        :show-message="false"
                                        label-width="120px"
                                    >
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="证书编号"
                                                    prop="cerNo"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="qualiForm.cerNo"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="证书名称"
                                                    prop="sname"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="qualiForm.sname"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="执业资格"
                                                    prop="cerGrade"
                                                >
                                                    <el-select
                                                        v-model="
                                                            qualiForm.type
                                                        "
                                                        @change="onChangeType($event, qualiForm)"
                                                        :disabled="isDisabled"
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in zyzg"
                                                            :key="'执业资格' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="登记专业"
                                                    prop="professionArr"
                                                >
                                                    <el-select
                                                        v-model="qualiForm.professionArr"
                                                        :disabled="isDisabled"
                                                        multiple
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in qualiForm.professionList"
                                                            :key="'登记专业' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="证书等级"
                                                    prop="grade"
                                                >
                                                    <el-select
                                                        v-model="qualiForm.grade"
                                                        :disabled="isDisabled"
                                                    >
                                                        <el-option
                                                            v-for="(m, n) in zsdj"
                                                            :key="'证书等级' + n"
                                                            :label="m.sname"
                                                            :value="m.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="发证单位"
                                                    prop="issueUnit"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="qualiForm.issueUnit"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="评审时间"
                                                    prop="issueDate"
                                                >
                                                    <el-date-picker
                                                        v-model="qualiForm.issueDate"
                                                        type="date"
                                                        :disabled="isDisabled"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="年-月-日"
                                                    >
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                </div>
                                <div class="pane-list-btn" v-show="editable">
                                    <el-button
                                        size="small"
                                        type="primary"
                                        class="mb10"
                                        icon="el-icon-plus"
                                        @click="addItem(inx)"
                                    ></el-button>
                                    <el-button
                                        size="small"
                                        type="danger"
                                        icon="el-icon-minus"
                                        @click="removeItem(qualiForm, inx)"
                                        v-if="inx !== 0"
                                    ></el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </template>

                    <!-- 其它 -->
                    <template v-if="item.attr === 'other'">
                        <el-row
                            class="pane-list-container"
                            v-for="(otherForm, inx) in otherList"
                            :key="'otherList' + inx"
                        >
                            <el-col :span="4" class="upload-container">
                                <div class="upload-box">
                                    <p>其它证书</p>
                                    <my-upload
                                        :ref="`other0_${inx}`"
                                        :isDisabled="isDisabled"
                                        width="200px"
                                        height="120px"
                                        listType="picture"
                                        :maxNum="1"
                                        :aid="otherForm.id"
                                        tableName="sys_user_other_cer"
                                        :assortment="`other0_${otherForm.id}`"
                                        :acceptType="['PNG', 'JPG', 'JPEG', 'PDF']"
                                    ></my-upload>
                                </div>
                            </el-col>
                            <el-col :span="20" class="flex">
                                <div class="pane-list-item">
                                    <el-form
                                        :ref="'otherForm' + inx"
                                        :model="otherForm"
                                        size="small"
                                        :show-message="false"
                                        label-width="120px"
                                    >
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="证书编号"
                                                    prop="cerNo"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="otherForm.cerNo"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="证书名称"
                                                    prop="cerName"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="otherForm.cerName"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="证书类型"
                                                    prop="cerType"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="otherForm.cerType"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="发证单位"
                                                    prop="issueUnit"
                                                >
                                                    <el-input
                                                        :disabled="isDisabled"
                                                        v-model="otherForm.issueUnit"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="发证日期"
                                                    prop="issueDate"
                                                >
                                                    <el-date-picker
                                                        v-model="otherForm.issueDate"
                                                        type="date"
                                                        :disabled="isDisabled"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="年-月-日"
                                                    >
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item
                                                    label="有效期至"
                                                    prop="dueDate"
                                                >
                                                    <el-date-picker
                                                        v-model="otherForm.dueDate"
                                                        type="date"
                                                        :disabled="isDisabled"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="年-月-日"
                                                    >
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                </div>
                                <div class="pane-list-btn" v-show="editable">
                                    <el-button
                                        size="small"
                                        type="primary"
                                        class="mb10"
                                        icon="el-icon-plus"
                                        @click="addItem(inx)"
                                    ></el-button>
                                    <el-button
                                        size="small"
                                        type="danger"
                                        icon="el-icon-minus"
                                        @click="removeItem(otherForm, inx)"
                                        v-if="inx !== 0"
                                    ></el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </template>
                </el-scrollbar>
            </el-tab-pane>
        </el-tabs>

        <!-- 操作按钮 -->
        <div class="btn-tools">
            <el-button size="mini" @click="editable = !editable" :loading="btnLoading">{{
                editable ? "锁定" : "修改"
            }}</el-button>
            <el-button size="mini" type="primary" :loading="btnLoading" @click="doSave"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
// api
import * as UserApi from "api/user/userIndex.js";
import { deleteFile } from 'api/upload/upload'

// js
import { mapState } from "vuex";
import { getInfoById } from "utils/tools";

export default {
    name: "",
    data() {
        return {
            activeTab: "baseInfo",
            btnLoading: false,
            editable: false,
            delFile: [],
            // 基本信息
            baseForm: {
                sname: "",
                username: "",
                phone: "",
                idCard: "",
                sex: "",
                nation: "",
                birthDate: "",
                age: "",
            },
            baseRules: {
                sname: [{ required: true, message: "请输入姓名" }],
                username: [{ required: true, message: "请输入用户名" }],
                phone: [
                    { required: true, message: "请输入手机号" },
                    {
                        pattern: /^1[3-9][0-9]{9}$/,
                        message: "手机号码格式不正确，请重新输入",
                    },
                ],
                idCard: [
                    {
                        pattern: /^[1-9][0-9]{5}(18|19|(2[0-9]))[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{3}[0-9Xx]$/,
                        message: "身份证输入有误",
                    },
                ],
            },
            // 工作经验
            workList: [
                {
                    startDate: "", // 开始日期
                    endDate: "", // 结束日期
                    dateRange: [], // 日期范围
                    company: "", // 公司名称
                    typeName: "", // 公司性质
                    functions: "", // 职能
                    industry: "", // 行业
                    postion: "", // 职位
                    post: "", // 岗位
                    description: "", // 工作描述
                    jobType: 1, // 工作类型
                },
            ],
            delWork: [], // 删除的
            // 项目经验
            projectList: [
                {
                    company: "", // 所属公司
                    startTime: "", // 开始日期
                    endTime: "", // 结束日期
                    proName: "", // 项目名称
                    proDes: "", // 项目描述
                    dutyDes: "", // 责任描述
                },
            ],
            delProject: [],
            // 学历
            educationList: [
                {
                    cerNo: "", // 证书编号
                    education: "", // 学历
                    degreeNature: "", // 学历性质
                    issueDate: "", // 证书取得日期
                    academy: "", // 毕业院校
                    profession: "", // 专业
                    degreeName: "", // 学位名称
                    degreeType: "", // 学位类型
                    years: "", // 学制（x 年）
                    isFull: 1, // 0否 1是 （是否全日制）
                },
            ],
            delEducation: [],
            // 职称
            careerList: [
                {
                    cerNo: "", // 证书编号
                    cerName: "", // 职称证书名称
                    cerGrade: "", // 职称（码表）
                    profession: "", // 职称专业（多选码表）
                    inspectDate: "", // 评审时间
                    inspectUnit: "", // 评审单位
                },
            ],
            delCareer: [],
            // 执业资格
            qualiList: [
                {
                    cerNo: "", // 证书编号
                    sname: "", // 执业资格证书名称
                    type: "", // 执业资格（码表）
                    profession: "", // 登记专业（多选码表）
                    professionArr: [], // 登记专业绑定数组
                    professionList: [], // 登记专业数据数组
                    grade: "", // 证书等级(码表)
                    issueUnit: "", // 发证单位
                    issueDate: "", // 发证日期
                },
            ],
            delQuali: [],
            // 其它
            otherList: [
                {
                    cerNo: "", // 证书编号
                    cerName: "", // 证书名称
                    cerType: "", // 证书类型
                    issueUnit: "", // 发证单位
                    issueDate: "", // 发证日期
                    dueDate: "", // 有效截止日期
                },
            ],
            delOther: [],
        };
    },
    created() {
        // 获取个人信息
        this.getBaseInfo();
    },
    methods: {
        // tab1 -------------- 基础信息
        // 获取基础信息
        getBaseInfo() {
            UserApi.getBasicInfo({ id: this.userInfo.id })
                .then((res) => {
                    this.baseForm = res.data;
                })
                .catch((err) => {});
        },

        // tab2 -------------- 工作经验
        // 获取工作经验
        getWorkInfo() {
            UserApi.getWork({ userId: this.userInfo.id })
                .then((res) => {
                    res.data.map((x) => {
                        x.dateRange = [];
                        x.startDate ? x.dateRange.push(x.startDate) : "";
                        x.endDate ? x.dateRange.push(x.endDate) : "";
                    });
                    this.workList = res.data;
                })
                .catch((err) => {});
        },

        // tab3 -------------- 项目经验
        // 获取项目经验
        getProInfo() {
            UserApi.getProExp({ userId: this.userInfo.id })
                .then((res) => {
                    res.data.map((x) => {
                        x.dateRange = [];
                        x.startTime ? x.dateRange.push(x.startTime) : "";
                        x.endTime ? x.dateRange.push(x.endTime) : "";
                    });
                    this.projectList = res.data;

                    // 删除
                    this.doClear()
                })
                .catch((err) => {});
        },

        // tab4 -------------- 学历
        // 获取学历列表
        getEduInfo(saveFile) {
            UserApi.getEduInfo({ userId: this.userInfo.id })
                .then((res) => {
                    this.educationList = res.data;

                    if (saveFile) {
                        // 保存附件
                        this.saveFile(res.data)

                        // 删除
                        this.doClear()
                    }
                })
                .catch((err) => {});
        },

        // tab5 -------------- 职称
        // 获取职称列表
        getCareerInfo(saveFile) {
            UserApi.getCareerInfo({ userId: this.userInfo.id })
                .then((res) => {
                    this.careerList = res.data;

                    if (saveFile) {
                        // 保存附件
                        this.saveFile(res.data)

                        // 删除
                        this.doClear()
                    }
                })
                .catch((err) => {});
        },

        // tab6 -------------- 执业资格
        // 获取执业资格列表
        getCerInfo(saveFile) {
            UserApi.getCerInfo({ userId: this.userInfo.id })
                .then((res) => {
                    res.data.map(x => {
                        x.professionArr = x.profession === '' ? [] : x.profession.split(',')
                        this.onChangeType(x.type, x)
                    })
                    this.qualiList = res.data;

                    if (saveFile) {
                        // 保存附件
                        this.saveFile(res.data)

                        // 删除
                        this.doClear()
                    }
                })
                .catch((err) => {});
        },

        // 执业资格切换 赋值 登记专业
        onChangeType(val, obj) {
            // console.log(val)
            let _item = this.zyzg.find(x => x.value === val)
            obj.professionList = _item ? _item.children : []
        },

        // tab7 -------------- 其它证书
        // 获取其他证书列表
        getOtherInfo(saveFile) {
            UserApi.getOtherInfo({ userId: this.userInfo.id })
                .then((res) => {
                    this.otherList = res.data;

                    if (saveFile) {
                        // 保存附件
                        this.saveFile(res.data)

                        // 删除
                        this.doClear()
                    }
                })
                .catch((err) => {});
        },

        // 切换tab
        onChangeTab() {
            // 清空数据
            this.doClear()

            switch (this.activeTab) {
                // 基础信息
                case "baseInfo":
                    this.getBaseInfo();
                    break;
                // 工作经验
                case "work":
                    this.getWorkInfo();
                    break;
                // 项目经验
                case "project":
                    this.getProInfo();
                    break;
                // 学历
                case "education":
                    this.getEduInfo();
                    break;
                // 职称
                case "career":
                    this.getCareerInfo();
                    break;
                // 执业资格
                case "qualification":
                    this.getCerInfo();
                    break;
                // 其他证书
                case "other":
                    this.getOtherInfo();
                    break;
            }
        },

        // 清空一些保存的
        doClear() {
            this.delFile = []
            this.delWork = []
            this.delProject = []
            this.delEducation = []
            this.delCareer = []
            this.delQuali = []
            this.delOther = []
        },

        // 添加元素
        addItem(index) {
            let item = {};
            switch (this.activeTab) {
                // 工作经验
                case "work":
                    item = {
                        startDate: "", // 开始日期
                        endDate: "", // 结束日期
                        dateRange: [], // 日期范围
                        company: "", // 公司名称
                        typeName: "", // 公司性质
                        functions: "", // 职能
                        industry: "", // 行业
                        postion: "", // 职位
                        post: "", // 岗位
                        description: "", // 工作描述
                        jobType: 1, // 工作类型
                    };
                    // this.workList.splice(index+1, 0, item);
                    this.workList.push(item)
                    break;
                // 项目经验
                case "project":
                    item = {
                        company: "", // 所属公司
                        dateRange: [], // 日期范围
                        startTime: "", // 开始日期
                        endTime: "", // 结束日期
                        proName: "", // 项目名称
                        proDes: "", // 项目描述
                        dutyDes: "", // 责任描述
                    };
                    // this.projectList.splice(index+1, 0, item);
                    this.projectList.push(item)
                    break;
                // 学历
                case "education":
                    item = {
                        cerNo: "", // 证书编号
                        education: "", // 学历
                        degreeNature: "", // 学历性质
                        issueDate: "", // 证书取得日期
                        academy: "", // 毕业院校
                        profession: "", // 专业
                        degreeName: "", // 学位名称
                        degreeType: "", // 学位类型
                        years: "", // 学制（x 年）
                        isFull: 1, // 0否 1是 （是否全日制）
                    };
                    // this.educationList.splice(index+1, 0, item);
                    this.educationList.push(item)
                    break;
                // 职称
                case "career":
                    item = {
                        id: '',
                        cerNo: "", // 证书编号
                        cerName: "", // 职称证书名称
                        cerGrade: "", // 职称（码表）
                        profession: "", // 职称专业（多选码表）
                        inspectDate: "", // 评审时间
                        inspectUnit: "", // 评审单位
                    }
                    // this.careerList.splice(index+1, 0, item);
                    this.careerList.push(item)
                    break;
                // 执业资格
                case "qualification":
                    item = {
                        cerNo: "", // 证书编号
                        sname: "", // 执业资格证书名称
                        type: "", // 执业资格（码表）
                        profession: "", // 登记专业（多选码表）
                        professionArr: [], // 登记专业绑定数组
                        professionList: [], // 登记专业数据数组
                        grade: "", // 证书等级(码表)
                        issueUnit: "", // 发证单位
                        issueDate: "", // 发证日期
                    },
                    // this.qualiList.splice(index+1, 0, item);
                    this.qualiList.push(item)
                    break;
                // 其他证书
                case "other":
                    item = {
                        cerNo: "", // 证书编号
                        cerName: "", // 证书名称
                        cerType: "", // 证书类型
                        issueUnit: "", // 发证单位
                        issueDate: "", // 发证日期
                        dueDate: "", // 有效截止日期
                    }
                    // this.otherList.splice(index+1, 0, item);
                    this.otherList.push(item)
                    break;
            }
        },

        // 移除元素
        removeItem(item, index) {
            switch (this.activeTab) {
                // 工作经验
                case "work":
                    // id 不存在， 直接删除
                    if (item.id) {
                        this.delWork.push(item);
                    }
                    this.workList.splice(index, 1);
                    // this.workList.push(item)
                    break;
                // 项目经验
                case "project":
                    // id 不存在， 直接删除
                    if (item.id) {
                        this.delProject.push(item);
                    }
                    this.projectList.splice(index, 1);
                    break;
                // 学历
                case "education":
                    // id 不存在， 直接删除
                    if (item.id) {
                        // 获取附件id
                        this.getFileId(index)
                        this.delEducation.push(item);
                    }
                    this.educationList.splice(index, 1);
                    break;
                // 职称
                case "career":
                    // id 不存在， 直接删除
                    if (item.id) {
                        // 获取附件id
                        this.getFileId(index)
                        this.delCareer.push(item);
                    }
                    this.careerList.splice(index, 1);
                    break;
                // 执业资格
                case "qualification":
                    // id 不存在， 直接删除
                    if (item.id) {
                        // 获取附件id
                        this.getFileId(index)
                        this.delQuali.push(item);
                    }
                    this.qualiList.splice(index, 1);
                    break;
                // 其他证书
                case "other":
                    // id 不存在， 直接删除
                    if (item.id) {
                        // 获取附件id
                        this.getFileId(index)
                        this.delOther.push(item);
                    }
                    this.otherList.splice(index, 1);
                    break;
            }
        },

        // 点击保存
        doSave() {
            this.btnLoading = true;
            this.editable = false;
            let arr = [],
                _delArr = [],
                _delIds = "";

            switch (this.activeTab) {
                // 基本信息保存
                case "baseInfo":
                    let params = this.$utils.clone(this.baseForm);

                    this.$refs.baseForm[0].validate((valid) => {
                        if (valid) {
                            UserApi.saveOneInfo(params)
                                .then((res) => {
                                    // 重新回显信息
                                    this.getBaseInfo();
                                    // 取消按钮状态
                                    this.resetBtnLoading();
                                })
                                .catch((err) => {
                                    // 取消按钮状态
                                    this.resetBtnLoading();
                                });
                        } else {
                            this.$message.warning("请填写关键信息！");

                            this.btnLoading = false;
                            this.editable = true;
                        }
                    });
                    break;
                // 工作经验
                case "work":
                    this.workList.map((x) => {
                        x.startDate = x.dateRange.length > 0 ? x.dateRange[0] : "";
                        x.endDate = x.dateRange.length > 0 ? x.dateRange[1] : "";
                    });
                    arr = JSON.parse(JSON.stringify(this.workList));
                    _delArr = this.delWork.map((x) => x.id);
                    _delIds = _delArr.join(",");

                    UserApi.saveWork({ workExpList: arr, delIds: _delIds })
                        .then((res) => {
                            // 重新回显信息
                            this.getWorkInfo();
                            // 取消按钮状态
                            this.resetBtnLoading();
                        })
                        .catch((err) => {
                            // 取消按钮状态
                            this.resetBtnLoading();
                        });
                    break;
                // 项目经验
                case "project":
                    this.projectList.map((x) => {
                        x.startTime = x.dateRange.length > 0 ? x.dateRange[0] : "";
                        x.endTime = x.dateRange.length > 0 ? x.dateRange[1] : "";
                    });
                    arr = JSON.parse(JSON.stringify(this.projectList));
                    _delArr = this.delProject.map((x) => x.id);
                    _delIds = _delArr.join(",");

                    UserApi.saveProExp({ proExpInfoList: arr, delIds: _delIds })
                        .then((res) => {
                            // 重新回显信息
                            this.getWorkInfo();
                            // 取消按钮状态
                            this.resetBtnLoading();
                        })
                        .catch((err) => {
                            // 取消按钮状态
                            this.resetBtnLoading();
                        });
                    break;
                // 学历
                case "education":
                    arr = JSON.parse(JSON.stringify(this.educationList));
                    _delArr = this.delEducation.map((x) => x.id);
                    _delIds = _delArr.join(",");

                    UserApi.saveEduInfo({ eduInfoList: arr, delIds: _delIds })
                        .then((res) => {
                            // 删除对应的附件
                            this.deleteFile()
                            
                            // // 保存附件
                            // this.saveFile(res.data)
        
                            // // 删除
                            // this.doClear()
                            // 重新回显信息
                            this.getEduInfo('doSave');
                            // 取消按钮状态
                            this.resetBtnLoading();
                        })
                        .catch((err) => {
                            // 取消按钮状态
                            this.resetBtnLoading();
                        });
                    break;
                // 职称
                case "career":
                    arr = JSON.parse(JSON.stringify(this.careerList));
                    _delArr = this.delCareer.map((x) => x.id);
                    _delIds = _delArr.join(",");

                    UserApi.saveCareerInfo({ practiceInfoList: arr, delIds: _delIds })
                        .then((res) => {
                            // 删除对应的附件
                            this.deleteFile()

                            // 重新回显信息
                            this.getCareerInfo('doSave');
                            // 取消按钮状态
                            this.resetBtnLoading();
                        })
                        .catch((err) => {
                            // 取消按钮状态
                            this.resetBtnLoading();
                        });
                    break;
                // 执业资格
                case "qualification":
                    this.qualiList.map((x) => {
                        x.profession = x.professionArr.join(",");
                    });
                    arr = JSON.parse(JSON.stringify(this.qualiList));
                    _delArr = this.delQuali.map((x) => x.id);
                    _delIds = _delArr.join(",");

                    UserApi.saveCerInfo({ cerInfoList: arr, delIds: _delIds })
                        .then((res) => {
                            // 删除对应的附件
                            this.deleteFile()

                            // 重新回显信息
                            this.getCerInfo('doSave');
                            // 取消按钮状态
                            this.resetBtnLoading();
                        })
                        .catch((err) => {
                            // 取消按钮状态
                            this.resetBtnLoading();
                        });
                    break;
                // 其他证书
                case "other":
                    arr = JSON.parse(JSON.stringify(this.otherList));
                    _delArr = this.delOther.map((x) => x.id);
                    _delIds = _delArr.join(",");

                    UserApi.saveOtherInfo({ otherCerInfoList: arr, delIds: _delIds })
                        .then((res) => {
                            // 删除对应的附件
                            this.deleteFile()

                            // 重新回显信息
                            this.getOtherInfo('doSave');
                            // 取消按钮状态
                            this.resetBtnLoading();
                        })
                        .catch((err) => {
                            // 取消按钮状态
                            this.resetBtnLoading();
                        });
                    break;
            }
        },

        // 保存附件
        saveFile(resArr) {
            resArr.map((item, index) => {
                // 学历证书
                let name1 = `${this.activeTab}0_${index}`
                this.$refs[name1] ? this.$refs[name1][0].fileSend(item.id) : ''

                // 学位证书
                let name2 = `${this.activeTab}1_${index}`
                this.$refs[name2] ? this.$refs[name2][0].fileSend(item.id) : ''
            })
        },

        // 获取附件id
        getFileId(index) {
            // 学历证书
            let name1 = `${this.activeTab}0_${index}`
            let _id1 = this.$refs[name1] && this.$refs[name1][0].fileList[0] ? this.$refs[name1][0].fileList[0].id : ''
            _id1 ? this.delFile.push(_id1) : ''

            // 学位证书
            let name2 = `${this.activeTab}1_${index}`
            let _id2 =  this.$refs[name2] && this.$refs[name2][0].fileList[0] ? this.$refs[name2][0].fileList[0].id : ''
            _id2 ? this.delFile.push(_id2) : ''
        },

        // 删除附件
        deleteFile() {
            let arr = JSON.parse(JSON.stringify(this.delFile))
            arr.map(x => {
                deleteFile({ id: x })
				    .then((res) => {})
				    .catch((err) => {})
            })
            
        },

        // 取消按钮 loading 状态
        resetBtnLoading() {
            setTimeout(() => {
                this.btnLoading = false;
            }, 2000);
        },
    },
    computed: {
        // tab 列表
        tabsList() {
            return [
                {
                    title: "基本信息",
                    attr: "baseInfo",
                },
                {
                    title: "工作经验",
                    attr: "work",
                },
                {
                    title: "项目经验",
                    attr: "project",
                },
                {
                    title: "学历",
                    attr: "education",
                },
                {
                    title: "职称",
                    attr: "career",
                },
                {
                    title: "执业资格",
                    attr: "qualification",
                },
                {
                    title: "其它",
                    attr: "other",
                },
            ];
        },

        ...mapState("user", {
            userInfo: (state) => state.userInfo,
        }),

        ...mapState("dict", {
            mz: (state) => state.projectDict.mz,
            dwxz: (state) => state.projectDict.dwxz,
            xlList: (state) => state.projectDict.zgxl,
            xlxz: (state) => state.projectDict.xlxz,
            xwlx: (state) => state.projectDict.zgxw,
            xwzy: (state) => state.projectDict.xwzy,
            grzc: (state) => state.projectDict.grzc,
            zczy: (state) => state.projectDict.zczy,
            zyzg: (state) => state.projectDict.zyzg,
            zsdj: (state) => state.projectDict.zsdj,
        }),

        // 是否不能编辑
        isDisabled() {
            return !this.editable;
        },
    },
    watch: {
        // 监听身份证的输入，计算出生日期 和 年龄
        "baseForm.idCard": {
            handler: function (val) {
                // console.log(this)
                let dateInfo = val && val !== "" ? getInfoById(val) : "";
                if (dateInfo.age) {
                    this.baseForm.age = dateInfo.age;
                    this.baseForm.birthDate = dateInfo.birthDate;
                    this.baseForm.sex = dateInfo.sex;
                } else {
                    this.baseForm.age = ''
                    this.baseForm.birthDate = ''
                    this.baseForm.sex = ''
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="less">
.userInfo-layout {
    height: 100%;
    padding: 0 10px;
    position: relative;

    .user-tabs {
        height: 100%;
        position: relative;
    }

    .el-tabs__header {
        margin-bottom: 10px;
    }

    .el-tabs__content {
        height: calc(~"100% - 50px");
        // padding: 0 20px;
    }

    .el-tab-pane {
        height: 100%;
        padding: 5px 10px;
    }

    .scrollbar-wrapper {
        overflow-x: hidden;
    }

    .btn-tools {
        position: absolute;
        right: 0;
        top: 0;
        height: 38px;
        line-height: 38px;
        background-color: #fff;
        z-index: 10;
        padding: 0 10px;
    }
}
</style>
<style lang="less" scoped>
.pane-list-container {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    border: 2px solid #f7f7f7;
    padding-top: 15px;

    @btnWidth: 80px;

    &:hover {
        box-shadow: 0px 1px 6px 1px #dddfe2;
    }

    .pane-list-item {
        flex: 1;
        width: calc(~"100% - @{btnWidth}");
        padding-right: 10px;
    }

    .pane-list-btn {
        width: @btnWidth;
        text-align: right;
        padding-right: 15px;
    }
}

.upload-container {
    width: 260px;
    padding: 0 30px;
    flex: 1;

    .upload-box {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 20px;
        }
    }

    p {
        color: #606266;
        font-weight: normal;
        font-size: 14px;
        line-height: 32px;
    }
}
</style>
